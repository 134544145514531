.loudspeaker {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.loudspeakerModal > .modal-content.modal-info > .modal-header {
  padding: 0px 20px 0px;
}

.loudspeakerModal > .modal-content.modal-info > .modal-body {
  padding: 0px 20px 20px 20px;
}

.loudspeakerTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}

.loudspeakerTitle > .robot.robot-sm {
  height: 110px;
  width: 70px;
}

.loudspeakerTitle > .robot > .robot-bg {
  transform: translate(0px,-20px) scale(.50)
}  

.loudspeakerTitle > .robot.robot-sm > .robot-figure {
  transform: scale(.50) translate(-45%) ;
}  

.loudspeakerTitle__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  font-size: 29px;
  color: #7a8797;
  text-align: center;
  font-weight: 500;
  margin: 0 15px;
}

.loudspeakerAnnouncement {
  display: flex;
  box-shadow: 2px 2px 2px 2px rgba(235, 235, 235, 1);
  margin-bottom: 15px;
  flex-direction: column;
}

.loudspeakerAnnouncement__icon {
  margin-top: -3px;
}

.loudspeakerAnnouncement__title {
  display: flex;
  flex-direction: column;
  padding: 2px 20px 0px 20px;
  margin-bottom: 4px;
}

.loudspeakerAnnouncement__snippet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 0px 20px 5px 20px;
  font-size: 12px;
}

.loudspeakerAnnouncement__snippet > div > :first-child {
  margin-top: 0;
  padding-top: 0;
}

.loudspeakerAnnouncement__snippet > div > :last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.loudspeakerAnnouncement__contentOnly {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 10px 20px 11px 20px;
  font-size: 12px;
}

.loudspeakerAnnouncement__contentOnly--withTags {
  padding: 10px 20px 0px 20px;
}

.loudspeakerAnnouncement__contentOnly > div > p {
  margin: 0px;
}

.loudspeakerAnnouncement__contentOnly > div > :first-child {
  margin-top: 0;
  padding-top: 0;
}

.loudspeakerAnnouncement__contentOnly > div > :last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.loudspeakerAnnouncement__contentOnly img {
  max-width: 450px;
}

.loudspeakerAnnouncement__tags {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  padding: 10px 20px 5px 20px;
}

.loudspeakerAnnouncement__tag {
  margin-right: 10px;
}

.loudspeakerAnnouncement__tagIcon {
  padding-right: 3px;
  color: #efa444;
}

.loudspeakerAnnouncement__readMoreLink {
  display: flex;
  width: -webkit-fit-content;
  width: fit-content;
  cursor: pointer;
  text-decoration: underline;
  padding: 0px 0px 11px 20px;
}

.loudspeakerAnnouncement__readMoreLink--withTags {
  padding: 0px 0px 0px 20px;
}

.loudspeakerViewAllButton {
  display: flex;
}

.loudspeakerViewAllButton__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.loudspeakerViewAllButton__link {
  background: none;
  color: inherit;
  border: none;
  margin-right: 15px;
  padding: 0;
  font: inherit;
  cursor: pointer;
  border-bottom: 1px solid rgb(65, 181, 220);
  color: rgb(65, 181, 220);
}

.loudspeakerViewAllButton__link:hover {
  color: none;
  text-decoration: none;
}

.loudspeakerViewAllButton__link:visited {
  text-decoration: none;
}

.loudspeakerViewAllButton__link:focus {
  text-decoration: none;
}

.loudspeakerFooter__secondaryButton {
  background: none;
  color: inherit;
  border: none;
  margin-right: 15px;
  padding: 0;
  font: inherit;
  cursor: pointer;
  border-bottom: 1px solid rgb(65, 181, 220);
  color: rgb(65, 181, 220);
}
.FulfillerSelect-Spinner-20 {
    height: 20px;
}

.FulfillerSelect-Spinner-text {
    margin-left: 0.5em;
}

.FulfillerSelect-vertical-center {
    display: inline-flex;
    align-items: center;
}

div.FulfillerSelect-option-group-label {
    box-sizing: border-box;
    background-color: #fff;
    color: #c4cdd6;
    display: block;
    padding-top: 12px;
}

.modal-overflow .modal-body {
  overflow-y: visible;
}

@charset "UTF-8";
/**
 * Mixin to create a keyframes for a notification
 * @param $direction (left|top|right|bottom) Translation direction
 * @param $margin-bottom Initial `margin-bottom` value
 */
/*
 * Mixin to create an animation for a notification
 * @param $direction (left|top|right|bottom) Translation direction
 * @param $margin-bottom Initial `margin-bottom` value
 */
@keyframes OT5RCY6dk-OgHvfJ3zvzl {
  0% {
    top: -360px;
    opacity: 0; }
  100% {
    top: 0;
    opacity: 1; } }

@keyframes _34tT5UDKlT9YzFJFftEHDd {
  0% {
    transform: translateY(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    transform: translateY(-100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes _2IqVg2POTm23CY51sqI1Ci {
  0% {
    bottom: -360px;
    opacity: 0; }
  100% {
    bottom: 0;
    opacity: 1; } }

@keyframes _1DL8zuMvhw7GsLfk8coOLJ {
  0% {
    transform: translateY(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    transform: translateY(100%);
    opacity: 0; }
  100% {
    transform: translateY(100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes _3ePuGFDlJh9qdk-GVF4nr9 {
  0% {
    left: -360px;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@keyframes _1m11d5_qfRC0aNCY2d_6ES {
  0% {
    transform: translateX(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    transform: translateX(-100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes _1almuAU8mitSz1AG23kcIV {
  0% {
    right: -360px;
    opacity: 0; }
  100% {
    right: 0;
    opacity: 1; } }

@keyframes _2uVAGD_jB3-l0f51W1w5hX {
  0% {
    transform: translateX(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    transform: translateX(100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes _5UPnRFbQAyIl9Gk1-lVSS {
  0% {
    top: -360px;
    opacity: 0; }
  100% {
    top: 0;
    opacity: 1; } }

@keyframes _1yjzX-3Ps9D83uSrzEwhob {
  0% {
    transform: translateY(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 0; }
  40% {
    margin-bottom: 0;
    max-height: 200px; }
  50% {
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    transform: translateY(-100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes _1L-oFbxQl1uhZOs6dOYSoB {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg); }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg); } }

._1QP6GtDEgkKfLiTjg4zatU {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.428571429; }

._3yL3MHCqkbDetrH4PCQ6a8 {
  position: fixed;
  z-index: 99999; }

._3GaWLZWxN2NsIK50JJxIDv, ._283JT4Pli2Dro-YEpI-JjD, ._1lKZQ1oGqXEn906m4S_yDW, ._2ftK7Ym89NGnKbio_A_IGp, ._2nd5IFTQ8VYuc7GsxIuCJ7, ._1hQbBnqyGghpiEvlCPatqA {
  width: 360px; }
  ._3GaWLZWxN2NsIK50JJxIDv ._37p2G_kbgdRzJBkb3xElVn ._1CatuaML4FWdViGY2wlqY5, ._283JT4Pli2Dro-YEpI-JjD ._37p2G_kbgdRzJBkb3xElVn ._1CatuaML4FWdViGY2wlqY5, ._1lKZQ1oGqXEn906m4S_yDW ._37p2G_kbgdRzJBkb3xElVn ._1CatuaML4FWdViGY2wlqY5, ._2ftK7Ym89NGnKbio_A_IGp ._37p2G_kbgdRzJBkb3xElVn ._1CatuaML4FWdViGY2wlqY5, ._2nd5IFTQ8VYuc7GsxIuCJ7 ._37p2G_kbgdRzJBkb3xElVn ._1CatuaML4FWdViGY2wlqY5, ._1hQbBnqyGghpiEvlCPatqA ._37p2G_kbgdRzJBkb3xElVn ._1CatuaML4FWdViGY2wlqY5 {
    height: 100%; }
  ._3GaWLZWxN2NsIK50JJxIDv ._2PoxA8Me9EDssd3h2Bx6O0 ._1CatuaML4FWdViGY2wlqY5, ._283JT4Pli2Dro-YEpI-JjD ._2PoxA8Me9EDssd3h2Bx6O0 ._1CatuaML4FWdViGY2wlqY5, ._1lKZQ1oGqXEn906m4S_yDW ._2PoxA8Me9EDssd3h2Bx6O0 ._1CatuaML4FWdViGY2wlqY5, ._2ftK7Ym89NGnKbio_A_IGp ._2PoxA8Me9EDssd3h2Bx6O0 ._1CatuaML4FWdViGY2wlqY5, ._2nd5IFTQ8VYuc7GsxIuCJ7 ._2PoxA8Me9EDssd3h2Bx6O0 ._1CatuaML4FWdViGY2wlqY5, ._1hQbBnqyGghpiEvlCPatqA ._2PoxA8Me9EDssd3h2Bx6O0 ._1CatuaML4FWdViGY2wlqY5 {
    height: 50%; }
  ._3GaWLZWxN2NsIK50JJxIDv .OQZVI4xMuLNohCldYZIFc, ._283JT4Pli2Dro-YEpI-JjD .OQZVI4xMuLNohCldYZIFc, ._1lKZQ1oGqXEn906m4S_yDW .OQZVI4xMuLNohCldYZIFc, ._2ftK7Ym89NGnKbio_A_IGp .OQZVI4xMuLNohCldYZIFc, ._2nd5IFTQ8VYuc7GsxIuCJ7 .OQZVI4xMuLNohCldYZIFc, ._1hQbBnqyGghpiEvlCPatqA .OQZVI4xMuLNohCldYZIFc {
    flex-direction: column; }
    ._3GaWLZWxN2NsIK50JJxIDv .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5, ._283JT4Pli2Dro-YEpI-JjD .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5, ._1lKZQ1oGqXEn906m4S_yDW .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5, ._2ftK7Ym89NGnKbio_A_IGp .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5, ._2nd5IFTQ8VYuc7GsxIuCJ7 .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5, ._1hQbBnqyGghpiEvlCPatqA .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5 {
      display: block;
      width: 100%; }
      ._3GaWLZWxN2NsIK50JJxIDv .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5:nth-child(2), ._283JT4Pli2Dro-YEpI-JjD .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5:nth-child(2), ._1lKZQ1oGqXEn906m4S_yDW .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5:nth-child(2), ._2ftK7Ym89NGnKbio_A_IGp .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5:nth-child(2), ._2nd5IFTQ8VYuc7GsxIuCJ7 .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5:nth-child(2), ._1hQbBnqyGghpiEvlCPatqA .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5:nth-child(2) {
        border-top: 1px solid rgba(0, 0, 0, 0.09); }

._2NEGqY_E9JhgxaO9vUIRGT .OQZVI4xMuLNohCldYZIFc .OQZVI4xMuLNohCldYZIFc, ._2NEGqY_E9JhgxaO9vUIRGT ._37p2G_kbgdRzJBkb3xElVn .OQZVI4xMuLNohCldYZIFc, ._1Zl6ukgKqyyBlxQ7aIJAJW .OQZVI4xMuLNohCldYZIFc .OQZVI4xMuLNohCldYZIFc, ._1Zl6ukgKqyyBlxQ7aIJAJW ._37p2G_kbgdRzJBkb3xElVn .OQZVI4xMuLNohCldYZIFc {
  flex-direction: row; }

._1Zl6ukgKqyyBlxQ7aIJAJW {
  width: calc(100% - 40px);
  top: 20px;
  left: 20px; }
  ._1Zl6ukgKqyyBlxQ7aIJAJW ._8wsdbvgNuxake7CrAufWp {
    position: relative;
    top: 0; }
    ._1Zl6ukgKqyyBlxQ7aIJAJW ._149a2G6P2uHldu4jTa_P_p {
      animation-fill-mode: forwards;
      animation-name: OT5RCY6dk-OgHvfJ3zvzl;
      animation-duration: .4s; }
    ._1Zl6ukgKqyyBlxQ7aIJAJW ._1vy5ysPLPW81jaen96rxIA {
      animation-fill-mode: forwards;
      animation-name: _34tT5UDKlT9YzFJFftEHDd;
      animation-duration: .8s; }

._3GaWLZWxN2NsIK50JJxIDv {
  top: 20px;
  left: 50%;
  transform: translateX(-50%); }
  ._3GaWLZWxN2NsIK50JJxIDv ._8wsdbvgNuxake7CrAufWp {
    position: relative;
    top: 0; }
    ._3GaWLZWxN2NsIK50JJxIDv ._149a2G6P2uHldu4jTa_P_p {
      animation-fill-mode: forwards;
      animation-name: OT5RCY6dk-OgHvfJ3zvzl;
      animation-duration: .4s; }
    ._3GaWLZWxN2NsIK50JJxIDv ._1vy5ysPLPW81jaen96rxIA {
      animation-fill-mode: forwards;
      animation-name: _34tT5UDKlT9YzFJFftEHDd;
      animation-duration: .8s; }

._283JT4Pli2Dro-YEpI-JjD {
  top: 20px;
  right: 20px; }
  ._283JT4Pli2Dro-YEpI-JjD ._8wsdbvgNuxake7CrAufWp {
    position: relative;
    right: 0; }
    ._283JT4Pli2Dro-YEpI-JjD ._149a2G6P2uHldu4jTa_P_p {
      animation-fill-mode: forwards;
      animation-name: _1almuAU8mitSz1AG23kcIV;
      animation-duration: .4s; }
    ._283JT4Pli2Dro-YEpI-JjD ._1vy5ysPLPW81jaen96rxIA {
      animation-fill-mode: forwards;
      animation-name: _2uVAGD_jB3-l0f51W1w5hX;
      animation-duration: .8s; }

._1lKZQ1oGqXEn906m4S_yDW {
  top: 20px;
  left: 20px; }
  ._1lKZQ1oGqXEn906m4S_yDW ._8wsdbvgNuxake7CrAufWp {
    position: relative;
    left: 0; }
    ._1lKZQ1oGqXEn906m4S_yDW ._149a2G6P2uHldu4jTa_P_p {
      animation-fill-mode: forwards;
      animation-name: _3ePuGFDlJh9qdk-GVF4nr9;
      animation-duration: .4s; }
    ._1lKZQ1oGqXEn906m4S_yDW ._1vy5ysPLPW81jaen96rxIA {
      animation-fill-mode: forwards;
      animation-name: _1m11d5_qfRC0aNCY2d_6ES;
      animation-duration: .8s; }

._2NEGqY_E9JhgxaO9vUIRGT {
  width: calc(100% - 40px);
  bottom: 0;
  left: 20px; }
  ._2NEGqY_E9JhgxaO9vUIRGT ._8wsdbvgNuxake7CrAufWp {
    position: relative;
    bottom: 0; }
    ._2NEGqY_E9JhgxaO9vUIRGT ._149a2G6P2uHldu4jTa_P_p {
      animation-fill-mode: forwards;
      animation-name: _2IqVg2POTm23CY51sqI1Ci;
      animation-duration: .4s; }
    ._2NEGqY_E9JhgxaO9vUIRGT ._1vy5ysPLPW81jaen96rxIA {
      animation-fill-mode: forwards;
      animation-name: _1DL8zuMvhw7GsLfk8coOLJ;
      animation-duration: .8s; }

._2ftK7Ym89NGnKbio_A_IGp {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }
  ._2ftK7Ym89NGnKbio_A_IGp ._8wsdbvgNuxake7CrAufWp {
    position: relative;
    bottom: 0; }
    ._2ftK7Ym89NGnKbio_A_IGp ._149a2G6P2uHldu4jTa_P_p {
      animation-fill-mode: forwards;
      animation-name: _2IqVg2POTm23CY51sqI1Ci;
      animation-duration: .4s; }
    ._2ftK7Ym89NGnKbio_A_IGp ._1vy5ysPLPW81jaen96rxIA {
      animation-fill-mode: forwards;
      animation-name: _1DL8zuMvhw7GsLfk8coOLJ;
      animation-duration: .8s; }

._2nd5IFTQ8VYuc7GsxIuCJ7 {
  bottom: 0;
  right: 20px; }
  ._2nd5IFTQ8VYuc7GsxIuCJ7 ._8wsdbvgNuxake7CrAufWp {
    position: relative;
    right: 0; }
    ._2nd5IFTQ8VYuc7GsxIuCJ7 ._149a2G6P2uHldu4jTa_P_p {
      animation-fill-mode: forwards;
      animation-name: _1almuAU8mitSz1AG23kcIV;
      animation-duration: .4s; }
    ._2nd5IFTQ8VYuc7GsxIuCJ7 ._1vy5ysPLPW81jaen96rxIA {
      animation-fill-mode: forwards;
      animation-name: _2uVAGD_jB3-l0f51W1w5hX;
      animation-duration: .8s; }

._1hQbBnqyGghpiEvlCPatqA {
  bottom: 0;
  left: 20px; }
  ._1hQbBnqyGghpiEvlCPatqA ._8wsdbvgNuxake7CrAufWp {
    position: relative;
    left: 0; }
    ._1hQbBnqyGghpiEvlCPatqA ._149a2G6P2uHldu4jTa_P_p {
      animation-fill-mode: forwards;
      animation-name: _3ePuGFDlJh9qdk-GVF4nr9;
      animation-duration: .4s; }
    ._1hQbBnqyGghpiEvlCPatqA ._1vy5ysPLPW81jaen96rxIA {
      animation-fill-mode: forwards;
      animation-name: _1m11d5_qfRC0aNCY2d_6ES;
      animation-duration: .8s; }

@media (max-width: 767px) {
  ._1Zl6ukgKqyyBlxQ7aIJAJW {
    width: 100%;
    top: 0;
    left: 0; }
    ._1Zl6ukgKqyyBlxQ7aIJAJW ._8wsdbvgNuxake7CrAufWp {
      position: relative;
      top: 0; }
      ._1Zl6ukgKqyyBlxQ7aIJAJW ._149a2G6P2uHldu4jTa_P_p {
        animation-fill-mode: forwards;
        animation-name: _5UPnRFbQAyIl9Gk1-lVSS;
        animation-duration: .4s; }
      ._1Zl6ukgKqyyBlxQ7aIJAJW ._1vy5ysPLPW81jaen96rxIA {
        animation-fill-mode: forwards;
        animation-name: _1yjzX-3Ps9D83uSrzEwhob;
        animation-duration: .8s; }
    ._1Zl6ukgKqyyBlxQ7aIJAJW ._8wsdbvgNuxake7CrAufWp {
      margin: 0; } }

._2Cn2Tjrk4xoa7zVGe4Uy_G {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 40px;
  border: none;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.3);
  z-index: 999; }
  ._8wsdbvgNuxake7CrAufWp {
    display: block;
    width: 100%;
    height: 100%;
    margin-bottom: 20px; }
  ._2K60Kme25TaUX-W3jSOudg {
    box-sizing: border-box;
    padding: 10px 0 10px 15px; }
  .SlstFiftRTUXEcGznE0An {
    display: inline-block;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    background-size: cover; }
  ._1bFBUJ5g0HO7w0plzpIt8y {
    box-sizing: border-box;
    margin: 10px 0 10px 15px;
    font-size: 20px !important;
    align-self: flex-start; }
  ._1hSQVzPPIvQKhkBXB3sM_2 {
    vertical-align: top;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 20px; }
  ._3O6nkcMuweiMNluqSqnxba {
    padding: 10px 15px;
    box-sizing: border-box; }
  ._2xnpTL8bgjiILDe5YkGals {
    font-size: 14px !important;
    color: #524c4c;
    cursor: pointer; }
    ._2xnpTL8bgjiILDe5YkGals:before {
      content: "\F00D"; }
    ._2xnpTL8bgjiILDe5YkGals:hover {
      color: #6c6565; }
  .OQZVI4xMuLNohCldYZIFc {
    display: flex;
    box-sizing: border-box;
    vertical-align: top; }
    .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5 {
      min-height: 40px;
      box-sizing: border-box;
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;
      border-left: 1px solid rgba(0, 0, 0, 0.09);
      outline: none;
      text-align: center;
      color: #524c4c;
      cursor: pointer; }
      .OQZVI4xMuLNohCldYZIFc ._1L56kFaBOEmHcrpnsKuCdx {
        display: block;
        height: 25px;
        padding: 0 15px;
        min-width: 90px;
        max-width: 150px;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        margin: 0 auto;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 14px;
        line-height: 25px; }
      .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5:hover {
        color: #349ef3; }
      .OQZVI4xMuLNohCldYZIFc ._1CatuaML4FWdViGY2wlqY5:active {
        color: #0e86e6; }
  ._2lNlAtZV38VXLrisJ75KRj {
    margin: 0 0 10px;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.428571429; }
  ._2W8eAWPB1SDTN9aLK5IocC {
    margin: 0; }
  ._2pQDDtXw13iSSmMsWP7miX {
    cursor: pointer; }
  ._7VKme9wwZCjmN7gma6BGK {
    background-color: #ffffff;
    border-left: 2px solid #ffffff;
    color: #524c4c; }
    ._7VKme9wwZCjmN7gma6BGK ._1bFBUJ5g0HO7w0plzpIt8y {
      display: none; }
  ._10XLztuPrSG1O9OLZEexX1 {
    background-color: #ffffff;
    border-left: 2px solid #4dc657;
    color: #524c4c; }
    ._10XLztuPrSG1O9OLZEexX1 ._1bFBUJ5g0HO7w0plzpIt8y:before {
      content: "\F058";
      color: #4dc657; }
  ._2cDFYswYXMQdeN9C7Q69w0 {
    background-color: #ffffff;
    border-left: 2px solid #349ef3;
    color: #524c4c; }
    ._2cDFYswYXMQdeN9C7Q69w0 ._1bFBUJ5g0HO7w0plzpIt8y:before {
      content: "\F05A";
      color: #349ef3; }
  ._2Uoa3JvyvGBMPfjTFdAFeV {
    background-color: #ffffff;
    border-left: 2px solid #f5aa0a;
    color: #524c4c; }
    ._2Uoa3JvyvGBMPfjTFdAFeV ._1bFBUJ5g0HO7w0plzpIt8y:before {
      content: "\F071";
      color: #f5aa0a; }
  .m6Smdm24QK8MW4NlxUbuw {
    background-color: #ffffff;
    border-left: 2px solid #f5311d;
    color: #524c4c; }
    .m6Smdm24QK8MW4NlxUbuw ._1bFBUJ5g0HO7w0plzpIt8y:before {
      content: "\F057";
      color: #f5311d; }
  ._3LipzQQChtwzEATCZaU8Ho {
    background-color: #ffffff;
    border-left: 2px solid #349ef3;
    color: #524c4c; }
    ._3LipzQQChtwzEATCZaU8Ho ._1bFBUJ5g0HO7w0plzpIt8y {
      animation: _1L-oFbxQl1uhZOs6dOYSoB 2s infinite linear; }
    ._3LipzQQChtwzEATCZaU8Ho ._1bFBUJ5g0HO7w0plzpIt8y:before {
      content: "\F110";
      color: #349ef3; }

